<template>
  <div notification-item-wrap class="no-drag">
    <div v-if="empty" class="no-list">
      {{ $t('_.noMessage') }}
    </div>
    <template v-else-if="isLoaded">
      <div v-if="newList.length" class="list new">
        <p class="status-label">{{ $t('_.unreadMessage') }}</p>
        <div class="notifi-li-cont">
          <component v-for="message in newList" :key="message.id" :is="useNoticeFallback(message.event)" :message="message"
            @delete-item="deleteItem" @read-item="readItem" />
        </div>
      </div>
      <div class="list">
        <p class="status-label">{{ $t('_.readMessage') }}</p>
        <div class="notifi-li-cont">
          <component v-for="message in pastList" :key="message.id" :is="useNoticeFallback(message.event)" :message="message"
            @delete-item="deleteItem" @read-item="readItem" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import LazyNotifyItems from '@shared/components/notification/itemTypes/LazyNotifyItems';
import { state } from '@shared/utils/storeUtils';

export default {
  name: 'NotificationItemWrap',
  lexicon: 'shared.notification',
  props: {
    newList: { type: Array, default: null },
    pastList: { type: Array, default: null },
  },
  components: LazyNotifyItems,
  computed: {
    unReadCount: state('notification', 'unReadCount'),
    empty() {
      if (!this.newList && !this.pastList) return false;

      const newMsg = this.newList;
      const pastMsg = this.pastList;
      return newMsg.length + pastMsg.length === 0;
    },
    isLoaded() {
      return Boolean(this.newList || this.pastList);
    },
  },
  methods: {
    useNoticeFallback(name) {
      return name in LazyNotifyItems ? name : 'NOTICE';
    },
    deleteItem(notificationId) {
      this.$emit('delete-item', notificationId);
    },
    async readItem({ notificationId, userNotificationId }) {
      this.$emit('close');
      notificationId = notificationId || userNotificationId;
      if(!notificationId) return;
      await this.$services.notification.markAsRead(notificationId);
      this.$store.commit('notification/setUnreadCount', this.unReadCount - 1);
    },
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';

[notification-item-wrap] {
  .status-label { .fs(14); .c(#a4a3ae); .mb(20); }

  > .list { .mt(20);
    &.new { .mb(50); }
    > p { .c(@c-placeholder); }
    > .notifi-li-cont {
      > div { .mt(20); }
    }
  }
  > .no-list { .c(#eee); .mt(20); }
}
</style>
