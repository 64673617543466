<template>
  <div notification-tab class="no-drag">
    <button :class="{active: selected === type}" @click="changeTab(type)" :key="type" v-for="type in list">
      <span>{{ $t(['_',type.toLowerCase()]) }}
        <em v-if="unreadCount[type]" class="unread-badge">{{ unreadCount[type] > 100 ? '+99' : unreadCount[type] }}</em>
      </span>
    </button>
    <span ref="slideBar" class="slide-bar"></span>
  </div>
</template>

<script>
export default {
  name: 'NotificationTab',
  lexicon: 'shared.notification',
  props: {
    list: { type: Array, default: () => [] },
    selected: { type: String, default: 'ARENA' },
    unreadCount: { type: Object, default: () => ({}) },
  },
  watch: {
    selected: 'drawSlideBar',
  },
  mounted() {
    this.drawSlideBar();
  },
  methods: {
    drawSlideBar() {
      this.$nextTick(() => {
        const targetEl = this.$el.querySelector('button.active');

        const { offsetLeft } = targetEl;
        const { clientWidth } = targetEl;
        this.$refs.slideBar.style.transform = `translateX(${offsetLeft}px)`;
        this.$refs.slideBar.style.width = `${clientWidth}px`;
      });
    },
    changeTab(value) {
      this.$emit('change', value);
    },
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';

[notification-tab] { .rel; .-b(@c-w03); .flex; .justify-start; .mt(18);
  button { .pv(12); .w(119); .fs(14); .c(@c-w04);
    &.active { .c(#FFF); }
    > span { .rel;
      > .unread-badge { .abs; .rt(-24, -8); .z(1); .flex-center; .wh(20); .fs(12, 20); .c(#FFF); .tc; .vam; .bgc(red); .br(50%); }
    }
  }
  .slide-bar { .abs; .lb(0, -2); .z(1); .h(2); .bgc(#FFF); transition: all 0.3s; }
}
</style>
