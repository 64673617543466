<template>
  <div notification-modal>
    <div class="dim" @click="close"></div>
    <div class="panel" :class="{ single: tabs.length === 1 }">
      <div class="header">
        <div class="title">
          <div class="user" v-t:_.sir="myInfo" />
          <button class="close" @click="close">
            <svg-x />
          </button>
        </div>
        <NotificationTab :selected="selected" :list="tabs" @change="changeTab" />
      </div>
      <slider ref="notificationSlider" :info="tabs" @changed="slideChange">
        <template v-slot:slide="{info}">
          <div class="scroll-area">
            <div :ref="`scrollArea${info}`" class="scroll-body">
              <div class="header no-drag">
                <h2>NOTIFICATION</h2>
                <p>{{ $t('_.title') }}</p>
              </div>
              <NotificationItemWrap :new-list="newMessages[info]" :past-list="pastMessages[info]"
                @delete-item="deleteNotification" @close="close" />
            </div>
          </div>
        </template>
      </slider>
    </div>
  </div>
</template>

<script>
import SvgX from '@shared/graphics/svg-x.vue';
import NotificationItemWrap from '@shared/components/notification/NotificationItemWrap.vue';
import NotificationTab from '@shared/components/notification/NotificationTab.vue';
import Slider from '@shared/components/common/Slider.vue';
import { state } from '@shared/utils/storeUtils';
import _forEach from 'lodash/forEach';
import _sum from 'lodash/sum';

export default {
  name: 'NotificationModal',
  lexicon: 'shared.notification',
  components: { Slider, NotificationTab, NotificationItemWrap, SvgX },
  data() {
    return {
      tabs: ['ARENA', 'LVUP'], // , 'WCG'
      selected: 'ARENA',
      newMessages: {
        ALL: null,
        ARENA: null,
        LVUP: null,
        WCG: null,
      },
      pastMessages: {
        ALL: null,
        ARENA: null,
        LVUP: null,
        WCG: null,
      },
      unreadCounts: {
        ALL: 0,
        ARENA: 0,
        LVUP: 0,
        WCG: 0,
      },
      omitTypes: ['TOURNAMENT_CHECKIN_CHANGED'],
    };
  },
  computed: {
    unReadCount: state('notification', 'unReadCount'),
    /** @type {MyInfo} */
    myInfo() {
      return this.$store.state.auth.authInfo || this.$store.state.auth.myInfo;
    },
    recentReceivedType: state('notification', 'recentReceivedType'),
  },
  watch: {
    $route() {
      this.close();
    },
    unReadCount() {
      if(_sum(Object.values(this.unreadCounts)) < this.unReadCount) {
        this.changeTab(this.recentReceivedType, true);
      }
    },
  },
  methods: {
    itemFilter(list) {
      return list.filter(item => !this.omitTypes.includes(item.event));
    },
    close() {
      this.$emit('close');
    },
    slideChange(activeIndex) {
      const selected = this.tabs[activeIndex];
      if (this.selected !== selected) this.changeTab(selected);
    },
    async changeTab(tab, reload = false) {
      if(!tab) return;
      if (this.selected === tab) this.$refs[`scrollArea${tab}`].scrollTo({ top: 0, behavior: 'smooth' });

      this.selected = tab;
      const prevTab = this.tabs.find(tab => tab !== this.selected);

      if(reload) await this.getNotifications(this.selected);

      this.markAsRead(prevTab);

      const activeIndex = this.tabs.findIndex(tab => tab === this.selected);
      this.$refs.notificationSlider && this.$refs.notificationSlider.go(activeIndex, 300);
    },
    async getNotifications(type) {
      const [newMsg, pastMsg] = await Promise.all([
        this.$services.notification.getMessages(type, false),
        this.$services.notification.getMessages(type, true),
      ]);

      this.newMessages[type] = this.itemFilter(newMsg.items);
      this.unreadCounts[type] = this.newMessages[type].length;
      this.pastMessages[type] = this.itemFilter(pastMsg.items);
    },
    async deleteNotification(notificationId) {
      await this.$services.notification.deleteMessage(notificationId);
      _forEach(this.newMessages, (messages, type) => this.newMessages[type] = messages?.filter(m => m.userNotificationId !== notificationId));
      _forEach(this.pastMessages, (messages, type) => this.pastMessages[type] = messages?.filter(m => m.userNotificationId !== notificationId));
    },
    markAsRead(type) {
      const notificationIds = this.newMessages[type].map(m => m.userNotificationId);
      this.pastMessages[type] = [...this.newMessages[type], ...this.pastMessages[type]];
      this.newMessages[type] = [];
      notificationIds.forEach(mId => this.$services.notification.markAsRead(mId));
      this.$store.commit('notification/setUnreadCount', _sum(Object.values(this.unreadCounts)));
    },
  },
  mounted() {
    if (window.location.host.includes('wcg') || window.location.pathname.includes('/wcg/') || window.location.host.includes('localhost:8085')) {
      this.tabs = ['ALL'];
      this.selected = 'ALL';
      this.omitTypes.push('MISSION_ACHIEVE');
    }
    this.tabs.forEach(t => this.getNotifications(t));
  },
  beforeDestroy() {
    this.markAsRead(this.selected);
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[notification-modal] { .abs; .f; .p(10); .flex-center;
  > .dim { .abs; .f; .lt; .bgc; }
  > .panel { .rel; .br(20); .bgc(#1f2226); .wh(100%); .crop; box-shadow: 0 0 1px 0 @c-w04;
    > .header { .p(30, 24, 0);
      .title { .flex; .items-center;
        .user { .c(#787781); .fs(14); .flex-grow; .flex; .gap(4); .max-w(calc(100% - 34px)); .mr(10);
          em { .c(#fff); .medium; .ellipsis(1); }
        }
        .close { .wh(24); .br(6); .bgc(@c-base-gray); .flex-no-shrink;
          .stroke-target { stroke: #000; .o(0.4); }
          @{hover-press} {
            .stroke-target { .o(1); }
          }
        }
      }
      .header-spacer { .h(62);}
    }
    [slider] { height: calc(100% - 117px);
      .swiper-container { .h(100%); }
      .scroll-area { .h(100%); .p(0, 24, 32);
        > .scroll-body { .hf; overflow-y: auto; overflow-x: hidden; .pt(32);
          > .header { .flex; .items-center; .mb(20);
            h2 { .chakra; .fs(24); .mr(6); .c(@c-base-gray); }
            p { .fs(13); .c(@c-w05); }
          }
        }
      }
    }
    &.single {
      [notification-tab] { .hide; }
      [slider] { .mt(20); height: calc(100% - 70px); }
    }
  }
  @media (@ml-up) {
    > .panel { .w(394); }
  }

  @media (@tp-up) {
    .p(20);
    > .panel { .w(414); .l(120); }
  }

  @media (@ds-up) {
    > .panel { .l(240); }
  }
}
</style>
